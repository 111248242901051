export enum SubscriptionType {
	Individual = "Individual",
	Corporate = "Corporate",
}

export enum ModeOfPayment {
	Transfer = "Transfer",
	Cheque = "Cheque",
	Others = "Others",
}
