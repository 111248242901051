import { MainLayout } from "../layout/main.layout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ModalComponent } from "../components/Modal";
import { useHistory } from "react-router-dom";
import { ErrorInterface, FormDataInterface } from "../interfaces";
import { ModeOfPayment, SubscriptionType } from "../enums";
export const Subscription: React.FC = () => {
	const history = useHistory();

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid },
	} = useForm<FormDataInterface>({
		mode: "all",
	});

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<ErrorInterface | null>(null);
	const [showModal, setShowModal] = useState<boolean>(false);

	const submitForm = async (data: FormDataInterface) => {
		console.log(process.env.REACT_APP_BASE_URL);
		try {
			setLoading(true);
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/share-holder/create`,
				{ ...data, amountPerUnit: 200 }
			);
			setLoading(false);
			history.push("/success", response.data);
		} catch (e) {
			setError(e["response"]["data"] as ErrorInterface);
			setShowModal(true);
			setLoading(false);
		}
	};

	const fullNameValidator = (data: string) => {
		const len = data.trim().split(" ");
		if (len.length < 2) return "please enter your full name ";
		return true;
	};

	const signatureValidator = (data: string) => {
		if (watch("fullName").trim().toLowerCase() !== data.trim().toLowerCase())
			return "your signature must be the same with your full name initially provided";
		return true;
	};

	return (
		<MainLayout pageName="Subscription Agreement">
			{/* show or hide the model when an error occurs */}
			<ModalComponent show={showModal} setShowModal={setShowModal}>
				{error?.message}
			</ModalComponent>
			{/*  */}
			<p className="content has-text-left has-text-weight-semibold has-text-dark is-size-6">
				Subscriber's Information
			</p>
			<form onSubmit={handleSubmit(submitForm)}>
				<fieldset disabled={loading}>
					<div className="columns">
						<div className="column">
							<div className=" field">
								<div className="columns">
									<div className="column is-4">
										<label className="is-small has-text-weight-bold has-text-black">
											Subscription type
										</label>
									</div>
									<div className="column">
										<label className="radio mr-4">
											<input
												type="radio"
												value={SubscriptionType.Individual}
												{...register("subscriptionType", {
													required: "please provide your full name ",
												})}
											/>
											<span className="ml-3 has-text-weight-bold">
												Individual
											</span>
										</label>
										<label className="radio">
											<input
												value={SubscriptionType.Corporate}
												type="radio"
												{...register("subscriptionType")}
											/>
											<span className="ml-3 has-text-weight-bold">
												Corporate
											</span>
										</label>
									</div>
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<div className="control">
										<label className="label is-small">
											Name (Surname First)
										</label>
										<input
											{...register("fullName", {
												required: "please provide your full name ",
												minLength: 6,
												validate: fullNameValidator,
											})}
											type="text"
											className="input"
										/>
									</div>

									<span className="help is-danger">
										{errors.fullName?.message}
									</span>
								</div>
								<div className="column">
									{" "}
									<div className="control">
										<label className="label is-small">Mobile Number</label>
										<input
											{...register("mobileNumber", {
												required: "please provide your mobile number ",
											})}
											type="text"
											className="input"
										/>
									</div>
								</div>
								<div className="column">
									{" "}
									<div className="control">
										<label className="label is-small">Email</label>
										<input
											{...register("email", {
												required: "please provide your email",
											})}
											type="email"
											className="input"
										/>
									</div>
								</div>
							</div>
							<div className="columns">
								<div className="control column">
									<label className="label is-small">Contact Address</label>
									<input
										type="text"
										className="input"
										{...register("mobileNumber", {
											required: "please provide a mobile number ",
										})}
									/>
								</div>
							</div>
							<div className="columns">
								<div className="control column is-8">
									<label className="label is-small">
										No of shares applied for (min of 1000)
									</label>
									<input
										{...register("noOfShares", {
											required:
												"please provide the number of shares you want to purchase  ",
											valueAsNumber: true,
											min: 1000,
											
										})}
										type="number"
										className="input"
									/>
									<span className="has-text-danger">
										{" "}
										{errors.noOfShares?.message}
									</span>
								</div>
								<div className="control column">
									<label className="label is-small">
										Share value applied for (N)
									</label>
									<input
										disabled
										type="text"
										className="input has-text-black"
										value={watch("noOfShares") * 200 || 0}
									/>
								</div>
							</div>
							<div className="columns ">
								<div className="column has-text-black has-text-weight-bold">
									Mode of payment{" "}
								</div>
								<div className="column">
									<div className="control">
										<label className="radio">
											<input
												{...register("modeOfPayment", {
													required: "please select your mode of payment ",
												})}
												value={ModeOfPayment.Transfer}
												type="radio"
											/>
											<span className="ml-3 has-text-weight-bold">
												Transfer
											</span>
										</label>
									</div>
								</div>
								<div className="column">
									<div className="control">
										<label className="radio">
											<input
												type="radio"
												{...register("modeOfPayment", {
													required: "please select your mode of payment ",
												})}
												value={ModeOfPayment.Cheque}
											/>
											<span className="ml-3 has-text-weight-bold">Cheque</span>
										</label>
									</div>
								</div>
								<div className="column">
									<div className="control">
										<label className="radio">
											<input
												{...register("modeOfPayment", {
													required: "please select your mode of payment ",
												})}
												value={ModeOfPayment.Others}
												name="modeOfPayment"
												type="radio"
											/>
											<span className="ml-3 has-text-weight-bold">Others</span>
										</label>
									</div>
								</div>
							</div>
							{watch("modeOfPayment") === ModeOfPayment.Others && (
								<div className="columns">
									<div className="field column has-background-grey-light">
										<div className="control">
											<label className="label is-small has-text-primary">
												Enter mode of payment
											</label>
											<input
												{...register("otherModeOfPayment", {
													required:
														"please enter your preferred mode of payment ",
												})}
												type="text"
												className="input"
											/>
										</div>
										<span className="help is-danger">
											{errors.otherModeOfPayment?.message}
										</span>
									</div>
								</div>
							)}

							<div className="columns">
								<div className="column">
									<p className="has-text-weight-bold mb-3 has-text-black">
										For dividend payment, please pay to my account below:
									</p>
									<div className="columns">
										<div className="column">
											<label className="label is-small">Bank Name</label>
											<input
												type="text"
												className="input"
												{...register("bankName", {
													required: "please provide your bank name",
												})}
											/>
										</div>
										<div className="column">
											<label className="label is-small">Account Name</label>
											<input
												type="text"
												className="input"
												{...register("accountName", {
													required: "please provide your account name",
												})}
											/>
										</div>
										<div className="column">
											<label className="label is-small">Account Number</label>
											<input
												type="text"
												className="input"
												{...register("accountNumber", {
													required: "please provide your account number",
													minLength: {
														value: 10,
														message:
															"please enter a valid NUBAN account number ",
													},
													maxLength: {
														value: 10,
														message:
															"please enter a valid NUBAN account number ",
													},
												})}
											/>
											<span className="help is-danger">
												{errors.accountNumber?.message}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<div className="column">
										<p className="has-text-weight-bold is-small has-text-black">
											Subscription acknowledgement
										</p>
									</div>

									<div className="column">
										{!errors.fullName && (
											<p>
												I{" "}
												<span className="px-2 has-text-weight-bold is-uppercase has-text-black is-underlined">
													{watch("fullName")}
												</span>
												acknowledge that this subscription is subject to
												acceptance by the Company’s Board of Directors and that
												upon acceptance, my subscription becomes a binding
												agreement between me and the Company. I acknowledge that
												the Company will not accept this subscription if I am a
												debtor of the Company or if the Company or any of its
												affiliates hold or have access to any of my assets
												(including by way of a mortgage interest against any of
												my assets).
											</p>
										)}
									</div>
									<div className="column content">
										<p className="has-text-weight-bold has-text-black">
											Risk acknowledgement
										</p>
										<ul className="list">
											<li>I acknowledge that this is a risky investment</li>
											<li>I am investing entirely at my own risk.</li>
											<li>
												No securities regulatory authority has evaluated or
												endorsed the merits of these securities
											</li>
											<li>
												The Company selling me these securities is not
												registered with a security’s regulatory authority and
												has no duty to tell me whether this investment is
												suitable for me.
											</li>
											<li>
												The securities are redeemable, but I may only be able to
												redeem them in limited circumstances.{" "}
											</li>
											<li>
												I will not be able to sell these securities for six
												months.
											</li>
											<li>I could lose all the money I invest.</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="columns">
								<div className="column">
									<div className="control">
										<label htmlFor="" className="label">
											Sign
										</label>
										<input
											{...register("signature", {
												required: "please provide your signature",
												validate: signatureValidator,
											})}
											type="text"
											className="input"
											placeholder="enter your full name"
										/>
									</div>
									<span className="help is-danger">
										{errors.signature?.message}
									</span>
								</div>

								<div className="column">
									<div className="control">
										<label htmlFor="" className="label">
											Date
										</label>
										<input
											value={new Date().toLocaleDateString()}
											type="text"
											className="input has-text-black has-text-weight-bold"
											disabled
											placeholder={new Date().toLocaleDateString()}
										/>
									</div>
								</div>
							</div>
							<div className="column has-text-centered">
								<button
									disabled={!isValid}
									type="submit"
									className={`button is-primary ${loading && "is-loading"}`}
								>
									SUBMIT
								</button>
							</div>
							{/* end of root column  */}
						</div>
						{/* end of columns */}
					</div>
				</fieldset>
			</form>
		</MainLayout>
	);
};
