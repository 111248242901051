import { Link, useHistory } from "react-router-dom";
import { MainLayout } from "../layout/main.layout";

export const App: React.FC = () => {
	const history = useHistory();
	return (
		<>
			<MainLayout pageName="Home Page">
				<div className="has-text-centered ">
					<p className="subtitle block has-text-primary has-text-weight-bold">
						Offer for Shares Subscription in Jetiffy Limited
					</p>
					<p className="is-subtitle block has-text-weight-semibold">
						A private placement of 250,000 units of shares at 200 naira per
						shares.
					</p>
					<Link to="/subscription" className="button is-primary block ">
						SUBSCRIBE
					</Link>
				</div>
			</MainLayout>
		</>
	);
};
