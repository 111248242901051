import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./pages/App";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { About } from "./pages/About";
import { Subscription } from "./pages/Subscription";
import { SuccessPage } from "./pages/Success";
import { StatusPage } from "./pages/Status";

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Switch>
				<Route exact path="/">
					<App />
				</Route>
				<Route exact path="/subscription">
					<Subscription />
				</Route>

				<Route exact path="/success">
					<SuccessPage />
				</Route>
				<Route exact path="/status/:code">
					<StatusPage />
				</Route>
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
