import { useState, useEffect } from "react";
export const ModalComponent: React.FC<{
	show: boolean;
	setShowModal: (param: boolean) => void;
}> = ({ show, children, setShowModal }) => {
	return (
		<div className={`modal ${show ? "is-active" : ""}`}>
			<div className="modal-background"></div>
			<div className="modal-content has-text-centered mx-3">
				<p className="has-text-dark subtitle box mx-4">{children}</p>
			</div>
			<button
				onClick={() => setShowModal(false)}
				className="modal-close is-large"
				aria-label="close"
			></button>
		</div>
	);
};
