import { MainLayout } from "../layout/main.layout";

export const SuccessPage: React.FC = () => {
	return (
		<MainLayout pageName="Request Successful">
			<div className="has-text-centered">
				<p className="has-text-weight-bold is-size-4 has-text-primary">
					Congrats your request has been submitted successfully.
				</p>
				<p>
					We are currently reviewing your request and will get back to you via
					mail. We look forward to having you on board.
				</p>
			</div>
		</MainLayout>
	);
};
