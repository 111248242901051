import { MainLayout } from "../layout/main.layout";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ModalComponent } from "../components/Modal";
import { useHistory, useParams } from "react-router-dom";
import { ErrorInterface, FormDataInterface } from "../interfaces";
import { ModeOfPayment, SubscriptionType } from "../enums";
export const StatusPage: React.FC = () => {
	const { code } = useParams<{ code: string }>();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<ErrorInterface>();
	const [defaults, setDefaults] = useState<FormDataInterface | null>(null);

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(
				`${process.env.REACT_APP_BASE_URL}/share-holder/get-status/${code}`
			);
			setDefaults((e) => {
				return { ...e, ...data };
			});
			console.log(defaults);
			setLoading(false);
		} catch (e) {
			const err = e["response"]["data"] as ErrorInterface;
			setError((e) => {
				return {
					...err,
				};
			});
			setLoading(false);
		}
	};
	const { register } = useForm<FormDataInterface>({
		defaultValues: {},
	});

	return (
		<MainLayout pageName="Subscription Status">
			{loading && (
				<progress className="progress is-small is-primary" max="100">
					15%
				</progress>
			)}
			{error && (
				<p className="has-text-danger has-text-weight-semibold has-text-centered">
					Sorry could not find request
				</p>
			)}
			{defaults && (
				<form>
					<fieldset disabled={true}>
						<div className="columns">
							<div className="column">
								<div className="columns field is-mobile has-background-grey">
									<div className="column">
										<label htmlFor="" className="label title">
											Request Status
										</label>
									</div>
									<div className="column has-text-right">
										<label htmlFor="" className="label title has-text-primary">
											{defaults.requestStatus}
										</label>
									</div>
								</div>
								<div className=" field">
									<div className="columns">
										<div className="column is-4">
											<label className="is-small has-text-weight-bold has-text-black">
												Subscription type
											</label>
										</div>
										<div className="column">
											<label className="radio mr-4 has-text-dark has-font-weight-semibold">
												<input
													type="radio"
													value={SubscriptionType.Individual}
													{...register("subscriptionType", {
														required: "please provide your full name ",
													})}
													checked={
														defaults.subscriptionType ===
														SubscriptionType.Individual
													}
												/>
												<span className="ml-3 has-text-weight-bold">
													Individual
												</span>
											</label>
											<label className="radio has-text-dark has-font-weight-semibold">
												<input
													value={SubscriptionType.Corporate}
													type="radio"
													{...register("subscriptionType")}
													checked={
														defaults.subscriptionType ===
														SubscriptionType.Corporate
													}
												/>
												<span className="ml-3 has-text-weight-bold">
													Corporate
												</span>
											</label>
										</div>
									</div>
								</div>
								<div className="columns">
									<div className="column">
										<div className="control">
											<label className="label is-small">
												Name (Surname First)
											</label>
											<input
												value={defaults.fullName}
												{...register("fullName", {
													required: "please provide your full name ",
													minLength: 6,
												})}
												type="text"
												className="input has-text-dark has-font-weight-semibold"
											/>
										</div>
									</div>
									<div className="column">
										{" "}
										<div className="control">
											<label className="label is-small">Mobile Number</label>
											<input
												{...register("mobileNumber", {
													required: "please provide your mobile number ",
												})}
												value={defaults.mobileNumber}
												type="text"
												className="input has-text-dark has-font-weight-semibold"
											/>
										</div>
									</div>
									<div className="column">
										{" "}
										<div className="control">
											<label className="label is-small">Email</label>
											<input
												{...register("email", {
													required: "please provide your email",
												})}
												value={defaults.email}
												type="email"
												className="input has-text-dark has-font-weight-semibold"
											/>
										</div>
									</div>
								</div>
								<div className="columns">
									<div className="control column">
										<label className="label is-small">Contact Address</label>
										<input
											value={defaults.address}
											type="text"
											className="input has-text-dark has-font-weight-semibold"
											{...register("mobileNumber", {
												required: "please provide a mobile number ",
											})}
										/>
									</div>
								</div>
								<div className="columns">
									<div className="control column is-8">
										<label className="label is-small">
											No of shares applied for (min of 1000)
										</label>
										<input
											{...register("noOfShares", {
												required:
													"please provide the number of shares you want to purchase  ",
												valueAsNumber: true,
												min: 1,
												max: 5000,
											})}
											value={defaults.noOfShares}
											type="number"
											className="input has-text-dark has-font-weight-semibold"
										/>
									</div>
									<div className="control column">
										<label className="label is-small">
											Share value applied for (N)
										</label>
										<input
											value={
												defaults.amountPerUnit &&
												defaults.noOfShares * defaults.amountPerUnit
											}
											disabled
											type="text"
											className="input has-text-black"
										/>
									</div>
								</div>
								<div className="columns ">
									<div className="column has-text-black has-text-weight-bold">
										Mode of payment{" "}
									</div>
									<div className="column">
										<div className="control">
											<label className="radio">
												<input
													checked={
														defaults.modeOfPayment === ModeOfPayment.Transfer
													}
													{...register("modeOfPayment", {
														required: "please select your mode of payment ",
													})}
													value={ModeOfPayment.Transfer}
													type="radio"
												/>
												<span className="ml-3 has-text-weight-bold">
													Transfer
												</span>
											</label>
										</div>
									</div>
									<div className="column">
										<div className="control">
											<label className="radio">
												<input
													checked={
														defaults.modeOfPayment === ModeOfPayment.Cheque
													}
													type="radio"
													{...register("modeOfPayment", {
														required: "please select your mode of payment ",
													})}
													value={ModeOfPayment.Cheque}
												/>
												<span className="ml-3 has-text-weight-bold">
													Cheque
												</span>
											</label>
										</div>
									</div>
									<div className="column">
										<div className="control">
											<label className="radio">
												<input
													checked={
														defaults.modeOfPayment !== ModeOfPayment.Transfer &&
														defaults.modeOfPayment !== ModeOfPayment.Cheque
													}
													{...register("modeOfPayment", {
														required: "please select your mode of payment ",
													})}
													value={ModeOfPayment.Others}
													name="modeOfPayment"
													type="radio"
												/>
												<span className="ml-3 has-text-weight-bold">
													Others
												</span>
											</label>
										</div>
									</div>
								</div>
								{/* <div className="columns">
									<div className="field column has-background-grey-light">
										<div className="control">
											<label className="label is-small has-text-primary">
												Enter mode of payment
											</label>
											<input
												{...register("otherModeOfPayment", {
													required:
														"please enter your preferred mode of payment ",
												})}
												type="text"
												className="input"
											/>
										</div>
									</div>
								</div> */}

								<div className="columns">
									<div className="column">
										<p className="has-text-weight-bold mb-3 has-text-black">
											For dividend payment, please pay to my account below:
										</p>
										<div className="columns">
											<div className="column">
												<label className="label is-small">Bank Name</label>
												<input
													type="text"
													value={defaults.bankName}
													className="input has-text-weight-semibold has-text-black"
													{...register("bankName", {
														required: "please provide your bank name",
													})}
												/>
											</div>
											<div className="column">
												<label className="label is-small">Account Name</label>
												<input
													type="text"
													value={defaults.accountName}
													className="input has-text-weight-semibold has-text-black"
													{...register("accountName", {
														required: "please provide your account name",
													})}
												/>
											</div>
											<div className="column">
												<label className="label is-small">Account Number</label>
												<input
													type="text"
													value={defaults.accountNumber}
													className="input has-text-weight-semibold has-text-black"
													{...register("accountNumber", {
														required: "please provide your account number",
														minLength: {
															value: 10,
															message:
																"please enter a valid NUBAN account number ",
														},
														maxLength: {
															value: 10,
															message:
																"please enter a valid NUBAN account number ",
														},
													})}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="columns">
									<div className="column">
										<div className="column">
											<p className="has-text-weight-bold is-small has-text-black">
												Subscription acknowledgement
											</p>
										</div>

										<div className="column">
											<p>
												I{" "}
												<span className="px-2 has-text-weight-bold is-uppercase has-text-black is-underlined">
													{defaults.fullName}
												</span>
												acknowledge that this subscription is subject to
												acceptance by the Company’s Board of Directors and that
												upon acceptance, my subscription becomes a binding
												agreement between me and the Company. I acknowledge that
												the Company will not accept this subscription if I am a
												debtor of the Company or if the Company or any of its
												affiliates hold or have access to any of my assets
												(including by way of a mortgage interest against any of
												my assets).
											</p>
										</div>
										<div className="column content">
											<p className="has-text-weight-bold has-text-black">
												Risk acknowledgement
											</p>
											<ul className="list">
												<li>I acknowledge that this is a risky investment</li>
												<li>I am investing entirely at my own risk.</li>
												<li>
													No securities regulatory authority has evaluated or
													endorsed the merits of these securities
												</li>
												<li>
													The Company selling me these securities is not
													registered with a security’s regulatory authority and
													has no duty to tell me whether this investment is
													suitable for me.
												</li>
												<li>
													The securities are redeemable, but I may only be able
													to redeem them in limited circumstances.{" "}
												</li>
												<li>
													I will not be able to sell these securities for six
													months.
												</li>
												<li>I could lose all the money I invest.</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="columns">
									<div className="column">
										<div className="control">
											<label htmlFor="" className="label">
												Sign
											</label>
											<input
												{...register("signature", {
													required: "please provide your signature",
												})}
												value={defaults.fullName}
												className="input has-text-weight-semibold has-text-black"
												placeholder="enter your full name"
											/>
										</div>
									</div>

									{defaults.createdAt && (
										<div className="column">
											<div className="control">
												<label htmlFor="" className="label">
													Date
												</label>
												<input
													value={new Date(
														defaults.createdAt
													).toLocaleDateString()}
													type="text"
													className="input has-text-black has-text-weight-bold"
													disabled
													placeholder={new Date().toLocaleDateString()}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			)}
		</MainLayout>
	);
};
