import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
export const MainLayout: React.FC<{ pageName: string }> = ({
	children,
	pageName,
}) => {
	return (
		<div className=" layout-container">
			<Link
				to="/"
				className="level has-background-primary  is-flex is-justify-content-center"
			>
				<figure className="image is-64x64  is-flex is-justify-content-center">
					<img src={logo} className="is-align-self-center" alt="" />
				</figure>
			</Link>
			<p className="has-text-centered is-size-5 mb-5 has-text-weight-bold has-text-black">
				{pageName}
			</p>
			<div className="box mx-4">{children}</div>
			<div className="footer has-background-grey">
				<div className="content has-text-centered">
					<p>
						<a
							className="has-text-primary"
							href="https://www.jetiffy.com"
							target="_blank"
						>
							&copy; Jetiffy Limited {new Date().getFullYear()}
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
